import api from './api';

// Fetch Swap Time MT5 (GET)
export const getSwapTimeMT5 = () => {
    // Matches the GET endpoint: http://localhost:3000/api/v1/crm/Swap-timeMT5/
    return api.get('/v1/crm/Swap-timeMT5');
};

// Update Swap Time MT5 (PUT)
export const updateSwapTimeMT5 = (id, data) => {
    // Matches the PUT endpoint: http://localhost:3000/api/v1/crm/Swap-timeMT5/:id
    return api.put(`/v1/crm/Swap-timeMT5/${id}`, data);
};
