import api from './api';

// Fetch Swap Time MT4 (GET)
export const getSwapTimeMT4 = () => {
    return api.get('/v1/crm/Swap-timeMT4');
};

// Update Swap Time MT4 (PUT)
export const updateSwapTimeMT4 = (id, data) => {
    return api.put(`/v1/crm/Swap-timeMT4/${id}`, data);
};
