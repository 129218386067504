import React, { useState } from 'react';
import { FinanceLotMt4, FinanceLotMt5 } from 'Services'; // Adjust this import to the correct path

function AccountLotsPage() {
  const [accountNumbers, setAccountNumbers] = useState(''); // To input multiple accounts
  const [platform, setPlatform] = useState('MT4'); // Platform selection
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [lotsData, setLotsData] = useState([]);

  // Symbols logic
  const excludedSymbols = ['ETHUSD', 'BCHUSD', 'DOTUSD', 'EOSUSD', 'LNKUSD', 'LTCUSD', 'RPLUSD', 'XLMUSD'];
  const yellowHighlightSymbols = ['NAS100', 'US30', 'ESP35', 'EUSTX50', 'FRA40', 'GER30', 'UK100', 'SPX500', 'US2000'];

  // Function to fetch lots
  const handleGetLots = async () => {
    setLoading(true);
    setMessage('');
    setLotsData([]);

    try {
      // Split account numbers into an array and filter valid numbers
      const loginValues = accountNumbers
        .split(',')
        .map(num => parseInt(num.trim()))
        .filter(num => !isNaN(num));

      if (loginValues.length === 0) {
        setMessage('Please enter valid account numbers separated by commas.');
        setLoading(false);
        return;
      }

      const requestData = { loginValues };
      console.log('Sending Request:', requestData);

      // Call appropriate service based on the selected platform
      const response =
        platform === 'MT4'
          ? await FinanceLotMt4.createFinanceLotMt4(requestData)
          : await FinanceLotMt5.createFinanceLotMt5(requestData);

      console.log('API Response:', response);

      if (Array.isArray(response)) {
        setLotsData(response);
        setMessage('Lots fetched successfully!');
      } else {
        console.error('Unexpected response structure:', response);
        setMessage('Failed to fetch lots. Please check the account numbers.');
      }
    } catch (error) {
      console.error('Error fetching lots:', error);
      setMessage('An error occurred while fetching lots.');
    } finally {
      setLoading(false);
    }
  };

  // Calculate Total Lots excluding unwanted symbols
  const totalLots = lotsData
    .filter((lot) => !excludedSymbols.includes(lot.Symbol) && lot.Symbol !== 'BTCUSD') // Exclude crypto symbols
    .reduce((total, lot) => total + parseFloat(lot.Lots || 0), 0)
    .toFixed(2);

  return (
    <div style={{ padding: '20px' }}>
      <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>Fetch Lots</h2>

      {/* Platform Selection Dropdown */}
      <div style={{ textAlign: 'center', marginBottom: '20px' }}>
        <select
          value={platform}
          onChange={(e) => setPlatform(e.target.value)}
          style={{
            padding: '10px',
            borderRadius: '4px',
            border: '1px solid #ccc',
            marginRight: '10px',
            width: '150px',
          }}
        >
          <option value="MT4">MT4</option>
          <option value="MT5">MT5</option>
        </select>
      </div>

      {/* Input for Multiple Accounts */}
      <div style={{ textAlign: 'center', marginBottom: '20px' }}>
        <input
          type="text"
          value={accountNumbers}
          onChange={(e) => setAccountNumbers(e.target.value)}
          placeholder="Enter account numbers (e.g., 100440,152121)"
          style={{
            width: '60%',
            padding: '10px',
            borderRadius: '4px',
            border: '1px solid #ccc',
            marginRight: '10px',
          }}
        />
        <button
          onClick={handleGetLots}
          style={{
            backgroundColor: '#007bff',
            color: '#fff',
            padding: '10px 20px',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
            opacity: loading ? 0.6 : 1,
            pointerEvents: loading ? 'none' : 'auto',
          }}
        >
          {loading ? 'Fetching...' : 'Get Lots'}
        </button>
      </div>

      {/* Message */}
      {message && (
        <div style={{ textAlign: 'center', color: message.includes('error') ? 'red' : 'green', marginBottom: '20px' }}>
          {message}
        </div>
      )}

      {/* Display Lots Data */}
      {lotsData.length > 0 && (
        <table style={{
          width: '100%',
          textAlign: 'center',
          borderCollapse: 'collapse',
          boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
          fontFamily: 'Arial, sans-serif',
          backgroundColor: '#fff',
        }}>
          <thead>
            <tr style={{ backgroundColor: '#007bff', color: 'white' }}>
              <th style={{ padding: '10px', borderBottom: '2px solid #ddd' }}>Login</th>
              <th style={{ padding: '10px', borderBottom: '2px solid #ddd' }}>Symbol</th>
              <th style={{ padding: '10px', borderBottom: '2px solid #ddd' }}>Lots</th>
            </tr>
          </thead>
          <tbody>
            {lotsData.map((lot, index) => {
              const isBTCUSD = lot.Symbol === 'BTCUSD';
              const isYellow = yellowHighlightSymbols.includes(lot.Symbol);

              return (
                <tr key={index} style={{ backgroundColor: isYellow ? '#fffacd' : '#ffffff' }}>
                  <td style={{ padding: '10px', borderBottom: '1px solid #ddd', color: isBTCUSD ? 'red' : '#333' }}>
                    {lot.Login}
                  </td>
                  <td style={{ padding: '10px', borderBottom: '1px solid #ddd', color: isBTCUSD ? 'red' : '#333' }}>
                    {lot.Symbol}
                  </td>
                  <td style={{ padding: '10px', borderBottom: '1px solid #ddd', color: isBTCUSD ? 'red' : '#333' }}>
                    {lot.Lots}
                  </td>
                </tr>
              );
            })}

            {/* Total Row */}
            <tr style={{ backgroundColor: '#e9ecef', fontWeight: 'bold' }}>
              <td style={{ padding: '10px', borderTop: '2px solid #007bff' }}></td>
              <td style={{ padding: '10px', borderTop: '2px solid #007bff', textAlign: 'right' }}>Total Lots:</td>
              <td style={{ padding: '10px', borderTop: '2px solid #007bff', color: '#007bff' }}>
                {totalLots}
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
}

export default AccountLotsPage;
