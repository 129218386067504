import React, { useState, useEffect } from "react";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  Paper,
  TextField,
} from "@mui/material";
import { OverallReport } from "Services/OverallReport.service";

function OverviewReport() {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filters, setFilters] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const response = await OverallReport();
        if (response) {
          setData(response);
          setFilteredData(response);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, []);

  const handleSort = (column) => {
    const isAsc = sortConfig.key === column && sortConfig.direction === "asc";
    setSortConfig({ key: column, direction: isAsc ? "desc" : "asc" });
  };

  const handleFilterChange = (column, value) => {
    setFilters((prev) => ({ ...prev, [column]: value }));
  };

  useEffect(() => {
    let filtered = data;

    Object.entries(filters).forEach(([key, value]) => {
      if (value) {
        filtered = filtered.filter((row) =>
          row[key]?.toString().toLowerCase().includes(value.toLowerCase())
        );
      }
    });

    setFilteredData(filtered);
  }, [filters, data]);

  const sortedData = React.useMemo(() => {
    if (!sortConfig.key) return filteredData;
    return [...filteredData].sort((a, b) => {
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];
      if (aValue < bValue) return sortConfig.direction === "asc" ? -1 : 1;
      if (aValue > bValue) return sortConfig.direction === "asc" ? 1 : -1;
      return 0;
    });
  }, [filteredData, sortConfig]);

  const paginatedData = React.useMemo(() => {
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    return sortedData.slice(startIndex, endIndex);
  }, [sortedData, page, rowsPerPage]);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const columns = [
    { id: "login", label: "A/C" },
    { id: "fullName", label: "Client" },
    { id: "lot", label: "Lot" },
    { id: "rebate", label: "Rebate" },
    { id: "platform", label: "Platform" },
    { id: "AccountType", label: "Account Type" },
    { id: "SalesPersonName", label: "Sales" },
    { id: "IBName", label: "IB Name" },
    { id: "currency", label: "Currency" },
    { id: "AccountGroup", label: "Account Group" },
    { id: "BALANCE", label: "Balance" },
    { id: "CREDIT", label: "Credit" },
    { id: "EQUITY", label: "Equity" },
    { id: "GroupType", label: "Group Type" },
    { id: "TotalDeposit", label: "Total Deposit" },
    { id: "TotalWithdraw", label: "Total Withdraw" },
    { id: "NetDeposit", label: "Net Deposit" },
    { id: "ClosedPL", label: "Closed P/L" },
    { id: "Swap", label: "Swap" },
    { id: "createdAt", label: "Created At" },
    { id: "isib", label: "IB Category" },
  ];

  return (
    <div>
           <style>
        {`
          .form-group.w-75 {
            display: none;
        
          }
            .form-inline.search-fulls.col-7 {
              border: none;
            }
        `}
      </style>
      <Typography variant="h6" style={{ marginBottom: "20px" }}>
        Overview Report
      </Typography>

      {isLoading ? (
        <Typography>Loading...</Typography>
      ) : (
        <Paper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id}>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <TableSortLabel
                          active={sortConfig.key === column.id}
                          direction={sortConfig.key === column.id ? sortConfig.direction : "asc"}
                          onClick={() => handleSort(column.id)}
                        >
                          {column.label}
                        </TableSortLabel>
                        <TextField
                          variant="outlined"
                          size="small"
                          placeholder={`Filter ${column.label}`}
                          onChange={(e) => handleFilterChange(column.id, e.target.value)}
                        />
                      </div>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedData.map((row, index) => (
                  <TableRow key={index}>
                    {columns.map((column) => (
                      <TableCell key={column.id}>
                        {column.id === "createdAt"
                          ? new Date(row[column.id]).toLocaleDateString()
                          : row[column.id] || "N/A"}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={filteredData.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      )}
    </div>
  );
}

export default OverviewReport;
