import React, { Fragment, useEffect, useState } from "react";
import { Col, Card, CardHeader, Table } from "reactstrap";
import { Deposits } from "../../../Constant";
import { H5 } from "../../../AbstractElements";
import { Row } from "react-bootstrap";
import DepositModalButton from "./DepositModalButton";
import ApprovedModal from "./Approved/ApprovedFormModal";
import RejectModal from "./Reject/RejectFormModal";
import { transactionsService } from "Services";
import Select from 'react-select';
import TableRowStatus from "CommonElements/Table/TableRowStatus";
import TableRowApprove from "CommonElements/Table/TableRowApprove";
import TableRowReject from "CommonElements/Table/TableRowReject";
import TablePagination from "CommonElements/Table/TablePagination";
import { formatDate, appendParameterToUrl, getParamsAndBody } from '../../Common/Utility';
import { useSelector } from "react-redux";
import { getMenuPerm } from 'utils';

import {DocumentsTransaction} from '../../../Services';




const HoverableRowsClass = () => {
  const [approvedModal, setApprovedModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [approvedModalData, setApprovedModalData] = useState([]);
  const [rejectModalData, setRejectModalData] = useState([]);
  const [limit, setLimit] = useState(10);
  const permissions = useSelector(
    (state) => (state.login && state.login.profile && state.login.profile.Role && state.login.profile.Role.permissions) || {}
  );

  const [rec, setRec] = useState({});
  const [expandedRow, setExpandedRow] = useState(null); // Track expanded row ID

  useEffect(() => {
    getListRecord(1);
  }, []);
  
  const getListRecord = (page = 1) => {
    const currentUrl = window.location.href;
    const query = getParamsAndBody(currentUrl);
    page = query.page || page;
    setRec({
      loading: true,
      data: [],
    });
    transactionsService
      .getCountryTransactions({ type: "DEPOSIT", page, limit: limit })
      .then((res) => {
        console.log("details for deposit is.......... ", res);
        setRec({
          ...res,
          loading: false,
        });
      })
      .catch((err) => {
      });
  };

  const toggle = (e, rowData) => {
    e.preventDefault();
    if (rowData !== undefined && rowData !== null && rowData.id !== 0) {
      console.log("Data is ", rowData);
      setApprovedModalData({ ...rowData });
    }
    setApprovedModal(!approvedModal);
  };

  //Reject Transaction
  const rejecttl = (e, rowData) => {
    e.preventDefault();
    if (rowData !== undefined && rowData !== null && rowData.id !== 0) {
      setRejectModalData({ ...rowData });
    }
    setRejectModal(!rejectModal);
  };

  const [filter, setFilter] = useState('');
  const [filteredData, setFilteredData] = useState(rec);

  const handleFilterChange = (event) => {
    const inputValue = event.target.value;
    setFilter(inputValue);
  };

  // Watch for changes in rec and filter, and update filteredData
  useEffect(() => {
    // Filter rec based on the input value
    const newFilteredData = rec && rec.data && rec.data.filter((item) => {
      return (item && item.id && item.id.toString().includes(filter)) || (item && item["Account.login"] && item["Account.login"].toString().includes(filter)) ||
        (item && item.gateway &&
          item.gateway.toLowerCase().includes(filter.toLowerCase())) ||
        (item && item.amount &&
          item.amount.toString().includes(filter.toLowerCase())) ||
        (item && item.status &&
          item.status.toLowerCase().includes(filter.toLowerCase()));
    });

    // Update the filtered data
    setFilteredData(newFilteredData);
  }, [rec, filter]);

  const options = [
    { value: '10', label: '10' },
    { value: '50', label: '50' },
    { value: '100', label: '100' },
    { value: '200', label: '200' },
    { value: '500', label: '500' },
    { value: '1000', label: '1000' }
  ]
  // Function to handle limit change
  const handleLimitChange = (selectedOption) => {
    const newLimit = selectedOption.value; // Use the selected option's value directly
    setLimit(newLimit); // Update the limit state
    transactionsService
      .getCountryTransactions({ type: "DEPOSIT", page: 1, limit: newLimit })
      .then((res) => {
        console.log("details for deposit is .................................................", res);
        setRec({
          ...res,
          limit: newLimit,
          loading: false,
        });
      })
      .catch((err) => {
      });
  };

  const toggleExpandRow = (rowId) => {
    setExpandedRow((prev) => (prev === rowId ? null : rowId));
  };

  const [editRowId, setEditRowId] = useState(null); // Track editing row ID
const [formData, setFormData] = useState({}); // Store editable form data

const handleEdit = (rowData) => {
  setEditRowId(rowData.id);
  setFormData({ ...rowData });
};

const handleSave = () => {
  console.log("Saved Data:", formData);

  const payload = {
    receivedBank: formData.receivedBank,
    bankCurrency: formData.bankCurrency,
    netMarkupsReceived: formData.netMarkupsReceived,
    bankReferenceNo: formData.bankReferenceNo,
    internalNotes: formData.internalNotes,
    depositedPerson: formData.depositedPerson,
    reconcile: !!formData.reconcile, // Ensure boolean value
    reconcileReviewedBy: formData.reconcile ? formData.reconcileReviewedBy : "",
    locked: !!formData.locked, // Ensure boolean value
  };

  transactionsService
    .patchDeposit(formData.id, payload)
    .then((res) => {
      console.log("Update Successful:", res);
      getListRecord(); // Refresh the table data
      setEditRowId(null);
      setFormData({});
    })
    .catch((err) => {
      console.error("Error updating deposit:", err);
    });
};

const handleFileUpload = (e, field) => {
  const file = e.target.files[0]; // Get the selected file
  console.log('file data: ', file);
  if (!file) return;

  const formData = new FormData();
  formData.append('transactionId', expandedRow);
  formData.append('document1', file); // Assuming the backend expects a `document1` key

  console.log("Uploading file with data: ", formData);

  DocumentsTransaction.addRecordTransaction(formData)
    .then((response) => {
      // Check if the response is an array
      if (Array.isArray(response) && response.length > 0 && response[0].file1) {
        console.log('File uploaded successfully:', response[0]);
        setFormData((prevData) => ({
          ...prevData,
          [field]: response[0].file1, // Update formData with the file URL
        }));
      } else {
        console.error('Unexpected response format:', response);
      }
    })
    .catch((error) => {
      console.error('Error uploading file:', error);
    });
};



const [viewModal, setViewModal] = useState(false);
const [deleteModal, setDeleteModal] = useState(false);
const [currentDocument, setCurrentDocument] = useState(null);

const handleViewDocument = (documentUrl) => {
  setCurrentDocument(documentUrl);
  setViewModal(true);
};

const handleDeleteDocument = (documentKey) => {
  setCurrentDocument(documentKey);
  setDeleteModal(true);
};

const confirmDeleteDocument = () => {
  // Handle the delete action here
  console.log(`Deleting document: ${currentDocument}`);
  setDeleteModal(false);
};


const handleCancel = () => {
  setEditRowId(null);
  setFormData({});
};

const handleChange = (e, field) => {
  setFormData({ ...formData, [field]: e.target.value });
};

const profile = useSelector((state) => state.login && state.login.profile);
console.log("Profile Data:", profile); // Inspect profile structure in console

const userName =
  (profile && profile.name) ||
  `${profile?.firstName || ""} ${profile?.lastName || ""}`.trim() ||
  "Unknown User";

const renderSubTable = (rowData) => {
  const isEditing = editRowId === rowData.id;

  // Inline Styles
  const rowStyle = { backgroundColor: "#f0f8ff" }; // Light blue for rows
  const cellStyle = { backgroundColor: "#e6e6fa", padding: "10px" }; // Lavender for cells
  const contentStyle = { backgroundColor: "#e6e6fa", padding: "5px", display: "block" }; // Same for strong & div
   // Check if the user is an admin
   const isAdmin = profile?.Role?.title === 'Admin1';
   console.log("edit permission .......................................",isAdmin)

  return (
    <tr>
      <td colSpan="14" style={{ padding: "0px" }}>
        <Table bordered className="mt-2">
          { <thead>
            <tr style={rowStyle}>
              <th colSpan="8" className="text-center">Bank Transaction Details</th>
            </tr>
          </thead> }
          <tbody>
            {/* First Row */}
            <tr style={rowStyle}>
            <td style={cellStyle}>
            <strong style={contentStyle}>Amount Received Bank:</strong>
            {isEditing ? (
              <input
                type="text" // Use text for custom validation
                value={formData.receivedBank || ""}
                onChange={(e) => handleChange(e, "receivedBank")}
                onInput={(e) => {
                  // Allow numbers and a single decimal point only
                  e.target.value = e.target.value.replace(/[^0-9.]/g, ""); // Remove invalid characters
                  e.target.value = e.target.value.replace(/(\..*)\./g, "$1"); // Prevent multiple decimal points
                }}
                className="form-control mt-1"
                placeholder="Enter amount"
              />
            ) : (
              <div style={contentStyle}>{rowData.receivedBank || "N/A"}</div>
            )}
          </td>

              <td style={cellStyle}>
              <strong style={contentStyle}>Bank Currency:</strong>
              {isEditing ? (
                <select
                  value={formData.bankCurrency || ""}
                  onChange={(e) => handleChange(e, "bankCurrency")}
                  className="form-control mt-1"
                >
                  <option value="">Select Currency</option>
                  <option value="AED">AED</option>
                  <option value="EUR">EUR</option>
                  <option value="USD">USD</option>
                </select>
              ) : (
                <div style={contentStyle}>{rowData.bankCurrency || "N/A"}</div>
              )}
            </td>
            <td style={cellStyle}>
              <strong style={contentStyle}>Net Markups Received:</strong>
              {isEditing ? (
                <input
                  type="text" // Use 'text' for custom validation
                  value={formData.netMarkupsReceived || ""}
                  onChange={(e) => handleChange(e, "netMarkupsReceived")}
                  onInput={(e) => {
                    // Allow only numbers and a single decimal point
                    e.target.value = e.target.value.replace(/[^0-9.]/g, ""); // Remove invalid characters
                    e.target.value = e.target.value.replace(/(\..*)\./g, "$1"); // Prevent multiple decimal points
                  }}
                  className="form-control mt-1"
                  placeholder="Enter amount"
                />
              ) : (
                <div style={contentStyle}>{rowData.netMarkupsReceived || "N/A"}</div>
              )}
            </td>

              <td style={cellStyle}>
                <strong style={contentStyle}>Bank Reference No:</strong>
                {isEditing ? (
                  <input
                    type="text"
                    value={formData.bankReferenceNo || ""}
                    onChange={(e) => handleChange(e, "bankReferenceNo")}
                    className="form-control mt-1"
                  />
                ) : (
                  <div style={contentStyle}>{rowData.bankReferenceNo || "N/A"}</div>
                )}
              </td>
              <td style={cellStyle}>
                <strong style={contentStyle}>Internal Notes:</strong>
                {isEditing ? (
                  <textarea
                    value={formData.internalNotes || ""}
                    onChange={(e) => handleChange(e, "internalNotes")}
                    className="form-control mt-1"
                    rows="1" // Adjust rows based on your UI preference
                  />
                ) : (
                  <div style={contentStyle}>{rowData.internalNotes || "N/A"}</div>
                )}
              </td>

              <td style={cellStyle}>
                <strong style={contentStyle}>Deposited Person:</strong>
                {isEditing ? (
                  <input
                    type="text"
                    value={formData.depositedPerson || ""}
                    onChange={(e) => handleChange(e, "depositedPerson")}
                    className="form-control mt-1"
                  />
                ) : (
                  <div style={contentStyle}>{rowData.depositedPerson || "N/A"}</div>
                )}
              </td>
              <td style={cellStyle}>
                <strong style={contentStyle}>IB No:</strong>
                {isEditing ? (
                  <input
                    type="text"
                    value={formData.IBid || ""}
                    onChange={(e) => handleChange(e, "IBid")}
                    className="form-control mt-1"
                    disabled={true}
                  />
                ) : (
                  <div style={contentStyle}>{rowData.IBid || "N/A"}</div>
                )}
              </td>
              <td style={cellStyle}>
                <strong style={contentStyle}>IB Name:</strong>
                {isEditing ? (
                  <input
                    type="text"
                    value={formData.ibname || ""}
                    onChange={(e) => handleChange(e, "ibName")}
                    className="form-control mt-1"
                    disabled={true}
                  />
                ) : (
                  <div style={contentStyle}>{rowData.ibname || "N/A"}</div>
                )}
              </td>
            </tr>

            {/* Second Row */}
            <tr style={rowStyle}>
              
              <td style={cellStyle}>
                <strong style={contentStyle}>Entity:</strong>
                {isEditing ? (
                  <input
                    type="text"
                    value={formData.entity || ""}
                    onChange={(e) => handleChange(e, "entity")}
                    className="form-control mt-1"
                    disabled={true}
                  />
                ) : (
                  <div style={contentStyle}>{rowData.entity || "N/A"}</div>
                )}
              </td>
              <td style={cellStyle}>
                <strong style={contentStyle}>RM Name:</strong>
                {isEditing ? (
                  <input
                    type="text"
                    value={formData.salesperson || ""}
                    onChange={(e) => handleChange(e, "salesperson")}
                    className="form-control mt-1"
                    disabled={true}
                  />
                ) : (
                  <div style={contentStyle}>{rowData.salesperson || "N/A"}</div>
                )}
              </td>

              <td style={cellStyle}>
  <strong style={contentStyle}>Documents:</strong>
  {!isEditing ? (
    <div style={contentStyle}>
      {rowData.documents1 ? (
        <div className="mb-2">
          <button
            className="btn btn-link"
            onClick={() => handleViewDocument(rowData.documents1)}
          >
            View
          </button>
          <button
            className="btn btn-danger ms-2"
            onClick={() => handleDeleteDocument('documents1')}
          >
            Delete
          </button>
        </div>
      ) : (
        <div className="mb-2">N/A</div>
      )}
      {rowData.documents2 ? (
        <div className="mb-2">
          <button
            className="btn btn-link"
            onClick={() => handleViewDocument(rowData.documents2)}
          >
            View
          </button>
          <button
            className="btn btn-danger ms-2"
            onClick={() => handleDeleteDocument('documents2')}
          >
            Delete
          </button>
        </div>
      ) : (
        <div className="mb-2">N/A</div>
      )}
    </div>
  ) : (
    <>
      <input
        type="file"
        onChange={(e) => handleFileUpload(e, 'documents1')}
        className="form-control mt-1"
      />
      <input
        type="file"
        onChange={(e) => handleFileUpload(e, 'documents2')}
        className="form-control mt-1"
      />
    </>
  )}
</td>


<td style={cellStyle}>
  <strong style={contentStyle}>Receipt Attachment:</strong>
  {isEditing ? (
    <>
      <input
        type="file"
        onChange={(e) => handleFileUpload(e, 'receiptAttachment1')}
        className="form-control mt-1"
      />
      <input
        type="file"
        onChange={(e) => handleFileUpload(e, 'receiptAttachment2')}
        className="form-control mt-1"
      />
    </>
  ) : (
    <div style={contentStyle}>
      {rowData.receiptAttachment1 || "N/A"} <br />
      {rowData.receiptAttachment2 || "N/A"} <br />
    </div>
  )}
</td>

              <td style={cellStyle}>
                  <strong style={contentStyle}>Reconcile:</strong>
                  {isEditing ? (
                    <select
                      value={formData.reconcile === true ? "Yes" : "No"}
                      onChange={(e) => {
                        const value = e.target.value === "Yes"; // Convert to boolean
                        setFormData({
                          ...formData,
                          reconcile: value,
                          reconcileReviewedBy: value ? userName : "", // Set reviewedBy only if Yes
                        });
                      }}
                      className="form-control mt-1"
                    >
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  ) : (
                    <div style={contentStyle}>{rowData.reconcile ? "Yes" : "No"}</div>
                  )}
                </td>


                <td style={cellStyle}>
  <strong style={contentStyle}>Reconcile By:</strong>
  {isEditing ? (
    <input
      type="text"
      value={formData.reconcileReviewedBy || ""}
      onChange={(e) => handleChange(e, "reconcileReviewedBy")}
      className="form-control mt-1"
      disabled={true} // Always disabled
    />
  ) : (
    <div style={contentStyle}>{rowData.reconcileReviewedBy || "N/A"}</div>
  )}
</td>

                <td style={cellStyle}>
                  <strong style={contentStyle}>Locked:</strong>
                  {isEditing ? (
                  <select
                    value={formData.locked ? "Yes" : "No"} // Map boolean to string
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        locked: e.target.value === "Yes", // Map string to boolean
                      })
                    }
                    className="form-control mt-1"
                  >
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                ) : (
                  <div>{rowData.locked ? "Yes" : "No"}</div>
                )}
              </td>

              <td style={cellStyle}>
               {isEditing ? (
                  <>
                    <button className="btn btn-success" onClick={handleSave}>
                      Save
                    </button>
                    <button
                      className="btn btn-secondary ms-2"
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                  </>
                ) : (
                  // Show Edit button to admins if locked, otherwise show to all
                  (rowData.locked ? isAdmin : true) && (
                    <button
                      className="btn btn-primary"
                      onClick={() => handleEdit(rowData)}
                    >
                      Edit
                    </button>
                  )
                )}
              </td>
            </tr>
          </tbody>
        </Table>
      </td>
    </tr>
  );
};

  
{viewModal && (
  <div className="modal show d-block" tabIndex="-1">
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">View Document</h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => setViewModal(false)}
          ></button>
        </div>
        <div className="modal-body">
          <iframe
            src={currentDocument}
            style={{ width: "100%", height: "400px" }}
            title="Document Viewer"
          ></iframe>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => setViewModal(false)}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
)}

{deleteModal && (
  <div className="modal show d-block" tabIndex="-1">
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Confirm Delete</h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => setDeleteModal(false)}
          ></button>
        </div>
        <div className="modal-body">
          Are you sure you want to delete this document?
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-danger"
            onClick={confirmDeleteDocument}
          >
            Delete
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => setDeleteModal(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
)}


  return (
    <Fragment>
      <ApprovedModal
        approvedModal={approvedModal}
        NewMessage="Approved Deposit"
        toggle={toggle}
        defaultVal="Default Value"
        setApprovedModal={setApprovedModal}
        approvedData={approvedModalData}
        refreshRecord={getListRecord}
      />
      <RejectModal
        rejectModal={rejectModal}
        NewMessage="Reject Deposit"
        rejecttl={rejecttl}
        defaultVal="Default Value"
        setRejectModal={setRejectModal}
        rejectData={rejectModalData}
        refreshRecord={getListRecord}
      />
      <Col sm="12">
        <Card>
          <CardHeader>
            <Row>
              <Col md="6">
                <H5>{Deposits}</H5>
              </Col>
              <Col md="6">
                <Row className="float-end">
                  <Col md="6">
                    <input
                      className="form-control"
                      type="text"
                      value={filter}
                      onChange={handleFilterChange}
                      placeholder="Search..."
                    />
                  </Col>
                  <Col md="3">
                    {getMenuPerm(permissions, 'transactions:create') === 1 &&
                      <DepositModalButton btnText="Add" defaultVal="Godo" refreshRecord={getListRecord} />
                    }
                  </Col>
                  <Col md="3">
                    <Select
                      defaultValue={options.find(option => option.value === limit.toString())}
                      onChange={handleLimitChange}
                      options={options}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardHeader>
          <div className="table-responsive">
            <Table hover striped>
              <thead>
                <tr>
                  <th scope="col">{"#"}</th>
                  <th scope="col">{"Date"}</th>
                  <th scope="col">{"Account"}</th>
                  <th scope="col">{"Name"}</th>
                  <th scope="col">{"TP Amount"}</th>
                  <th scope="col">{"TP Currency"}</th>
                  <th scope="col">{"Req Amount"}</th>
                  <th scope="col">{"Req Currency"}</th>
                  <th scope="col">{"Fee"}</th>
                  <th scope="col">{"Gateway"}</th>
                  <th scope="col">{"Deal Id"}</th>  
                  <th scope="col">{"note"}</th>
                  <th scope="col">{"Status"}</th>
                  <th scope="col">{"Action"}</th>
                </tr>
              </thead>
              <tbody>
                {filteredData && filteredData.length > 0 ? (
                  filteredData.map((item, index) => (
                    <Fragment key={index}>
                      <tr>
                        <th scope="row">{item.id}</th>
                        <th scope="row">{formatDate(new Date(item.createdAt))}</th>
                        <td>
                          {item["Account.login"] || `${item["AccountFrom.login"]} -> ${item["AccountTo.login"]}`}
                        </td>
                        <td>
                          {item["Account.Customer.firstName"] + ' ' + (item["Account.Customer.lastName"] || '')}
                        </td>
                        <td>{Math.floor(item.paid)}</td>
                        <td>{item["Account.currency"]}</td>
                        <td>{Math.floor(item.amount)}</td>
                        <td>{item.depositCurrency}</td>
                        <td>{item.fee}</td>
                        <td>{item.gateway}</td>
                        <th scope="row">{item.dealId}</th>
                        <td>{item.note}</td>
                        <td>
                          <TableRowStatus status={item.status} />
                        </td>
                        <td>
                          {item.action}
                          {item.status === 'PENDING' && getMenuPerm(permissions, 'transactions:actions') === 1 && (
                            <Fragment>
                              <a href="#javascript" className="tble_edit">
                                <TableRowApprove onClick={(e) => toggle(e, item)} />
                              </a>
                              <a href="#javascript" className="tble_delete">
                                <TableRowReject onClick={(e) => rejecttl(e, item)} />
                              </a>
                            </Fragment>
                          )}
                      {[33, 35].includes(profile?.Role?.id) && (
                          <a
                            href="#javascript"
                            onClick={() => toggleExpandRow(item.id)}
                            style={{
                              color: "#007bff",
                              fontSize: "18px",
                              marginLeft: "5px",
                              cursor: "pointer",
                            }}
                          >
                            <i className="fa fa-arrow-down"></i>
                          </a>
                        )}

                        </td>
                      </tr>
                      {expandedRow === item.id && renderSubTable(item)}
                    </Fragment>
                  ))
                ) : (
                  ''
                )}
              </tbody>
            </Table>
            <TablePagination data={rec} loadData={getListRecord} limit={limit} param={appendParameterToUrl} />
          </div>
        </Card>
      </Col>
    </Fragment>
  );
};

export default HoverableRowsClass;
