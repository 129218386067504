import React, { Fragment, useState, useEffect } from 'react';
import {
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Table,
} from 'reactstrap';
import DataTable from 'react-data-table-component';
import Select from 'react-select';
import { swapGroup, swapTimeMT4,swapTimeMT5 } from 'Services'; // Adjust this import to the correct path
import api from 'Services/api'; // Adjust this import to the correct path
import { toast } from 'react-toastify';
import { TextField, TablePagination } from '@mui/material';

const SwapGroupMapping = () => {
  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false); // State for delete confirmation modal
  const [selectedRows, setSelectedRows] = useState([]); // State for selected rows
  const [formValues, setFormValues] = useState({
    groupName: '',
    platform: '',
    swapFreeDays: '',
  });
  const [data, setData] = useState([]); // State variable for storing data
  const [dataTimeMT4, setDataTimeMT4] = useState([]); // State variable for storing data
  const [dataTimeMT5, setDataTimeMT5] = useState([]); // State variable for MT5 data
  const [editModal, setEditModal] = useState(false); // Edit modal state
  const [editingRow, setEditingRow] = useState(null); // Row being edited
  const [editFormValues, setEditFormValues] = useState({
    day: '',
    time: '',
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const toggleEditModal = () => setEditModal(!editModal);

  const handleEditClick = (row) => {
    setEditingRow(row);
    setEditFormValues({
      id: row.id, // Capture slno but do not display it
      day: row.days,
      time: row.time,
    });
    toggleEditModal();
  };
  

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditFormValues({ ...editFormValues, [name]: value });
  };

  const handleEditSubmit = () => {
    const { id, time } = editFormValues;
  
    swapTimeMT4.updateSwapTimeMT4(id, { time }) // Use slno here
      .then(() => {
        toast.success('Swap time updated successfully!');
        setDataTimeMT4((prevData) =>
          prevData.map((row) =>
            row.id === id ? { ...row, time } : row
          )
        );
        toggleEditModal();
      })
      .catch((error) => {
        console.error('Error updating swap time:', error);
        toast.error('Error updating swap time');
      });
  };
  

  const toggle = () => setModal(!modal);
  const toggleDeleteModal = () => setDeleteModal(!deleteModal); // Toggle delete confirmation modal

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSelectChange = (selectedOption) => {
    setFormValues({ ...formValues, platform: selectedOption.value });
  };

  const handleKeyDown = (e) => {
    const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'];
    if (!/^[0-9]$/.test(e.key) && !allowedKeys.includes(e.key)) {
      e.preventDefault();
    }
  };

  const checkIfGroupExists = () => {
    return data.some(group => group.GroupName === formValues.groupName && group.Platform === formValues.platform);
  };

  const handleSubmit = () => {
    if (checkIfGroupExists()) {
      toast.error('Group already exists!');
      return;
    }

    const payload = {
      GroupName: formValues.groupName,
      daysofgroup: parseInt(formValues.swapFreeDays, 10),
      Platform: formValues.platform,
    };

    api.post('/v1/crm/SwapGroup/SwapGroupCreate', payload)
      .then((res) => {
        if (res && res.data && res.data.success) {
          setData([...data, res.data.data]); // Add the new entry to the data
          setFormValues({ groupName: '', platform: '', swapFreeDays: '' });
          toggle();
          toast.success('Swap group added successfully!');
          setTimeout(() => {
            window.location.reload(); // Refresh the page
          }, 2000); // Adjust the timeout as needed
        } else {
          toast.success('Swap group added successfully!');
          setTimeout(() => {
            window.location.reload(); // Refresh the page
          }, 2000); // Adjust the timeout as needed
        }
      })
      .catch((error) => {
        console.error('Error adding swap group:', error.response ? error.response.data : error.message);
        toast.error("Error adding swap group");
      });
  };

  const handleRowSelected = (selectedRows) => {
    setSelectedRows(selectedRows.selectedRows);
  };

  const handleDelete = () => {
    const idsToDelete = selectedRows.map(row => row.slno);
    const deletePromises = idsToDelete.map(id => api.delete(`/v1/crm/SwapGroup/SwapGroup/${id}`));

    Promise.all(deletePromises)
      .then(() => {
        setData(data.filter(row => !idsToDelete.includes(row.slno))); // Remove deleted entries from data
        toggleDeleteModal();
        toast.success('Swap group removed successfully!');
      })
      .catch((error) => {
        console.error('Error deleting swap groups:', error);
        toast.error("Error deleting swap groups");
      });
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0); // Reset to the first page when a search is performed
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const swapGroupColumns = [
    { name: 'Sl. No', selector: (row) => row.slno, sortable: true },
    { name: 'Group Name', selector: (row) => row.GroupName, sortable: true },
    { name: 'Swap Free Days', selector: (row) => row.daysofgroup, sortable: true },
    { name: 'Platform', selector: (row) => row.Platform, sortable: true },
  ];
  

  const swapChargingTimeColumns = [
    { name: 'Day', selector: (row) => row.days, sortable: true },
    { name: 'Time', selector: (row) => row.time, sortable: true },
    {
      name: 'Actions',
      cell: (row) => (
        <Button color="primary" size="sm" onClick={() => handleEditClick(row)}>
          Edit
        </Button>
      ),
    },
  ];
    

  const filteredData = data.filter((row) =>
    Object.values(row).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  useEffect(() => {
    swapTimeMT4.getSwapTimeMT4()
    .then((res) => {
      if (res) {
        setDataTimeMT4(res); // Ensure slno is part of this response
      } 
    })
    .catch((error) => {
      console.error('Error fetching swap times:', error);
      toast.error('Error fetching swap times');
    });  
  }, []);
  
  useEffect(() => {
    swapTimeMT5.getSwapTimeMT5()
      .then((res) => {
        if (res) {
          setDataTimeMT5(res); // Ensure `res` contains the correct structure
        }
      })
      .catch((error) => {
        console.error('Error fetching swap times for MT5:', error);
        toast.error('Error fetching swap times for MT5');
      });
  }, []);
  

  useEffect(() => {
    swapGroup.swapGroup()
      .then((res) => {
        setData(res); // Setting the response directly to data
      })
      .catch((error) => {
        console.error('Error fetching swap group data:', error);
        toast.error("Error fetching swap group data");
      });
  }, []);

  return (
    <Fragment>
      <div style={{ height: '30px' }} className="container-fluid">
        <div className="page-title" style={{ display: 'none' }}>
          <div className="row">
            <div className="col-6">
              <h3></h3>
            </div>
            <div className="col-6">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/dashboard">Dashboard</a>
                </li>
                <li className="breadcrumb-item active">Clients</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

    <Col sm="12">
  <Card>
    <CardHeader className="d-flex justify-content-between align-items-center">
      <h5>Swap Group</h5>
      <div>
        <Button color="primary" onClick={toggle} style={{ marginRight: '15px' }}>
          Add
        </Button>
        <Button color="danger" onClick={toggleDeleteModal} disabled={selectedRows.length === 0}>
          Delete
        </Button>
      </div>
    </CardHeader>
    <CardBody>
      <TextField
        label="Group Search"
        variant="outlined"
        fullWidth
        sx={{ marginBottom: 2 }}
        onChange={handleSearch}
      />
      <DataTable
        data={filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
        columns={swapGroupColumns}
        striped
        selectableRows
        onSelectedRowsChange={handleRowSelected}
        persistTableHead
      />
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100, 250, 500, 1000]}
        component="div"
        count={filteredData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          '& .MuiTablePagination-selectLabel': {
            marginBottom: 0,
          },
          '& .MuiTablePagination-displayedRows': {
            marginBottom: 0,
          },
        }}
      />
      <div className="row mt-4">
        <Col sm="6">
          <Card>
            <CardHeader>
              <h6>Swap Charging Time for MT4</h6>
            </CardHeader>
            <CardBody>
          <DataTable
            data={dataTimeMT4}
            columns={swapChargingTimeColumns}
            striped
            persistTableHead
          />
        </CardBody>
          </Card>
        </Col>
        <Col sm="6">
          <Card>
            <CardHeader>
              <h6>Swap Charging Time for MT5</h6>
            </CardHeader>
            <CardBody>
            <DataTable
                  data={dataTimeMT5}
                  columns={swapChargingTimeColumns}
                  striped
                  persistTableHead
                />
            </CardBody>
          </Card>
        </Col>
      </div>
    </CardBody>
  </Card>
</Col>


      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Add Swap Group</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="groupName">Group Name</Label>
              <Input
                type="text"
                name="groupName"
                id="groupName"
                value={formValues.groupName}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="platform">Platform</Label>
              <Select
                id="platform"
                value={platformOptions.find(option => option.value === formValues.platform)}
                onChange={handleSelectChange}
                options={platformOptions}
              />
            </FormGroup>
            <FormGroup>
              <Label for="swapFreeDays">Swap Free Days</Label>
              <Input
                type="number"
                name="swapFreeDays"
                id="swapFreeDays"
                value={formValues.swapFreeDays}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSubmit}>Submit</Button>
          <Button color="secondary" onClick={toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={deleteModal} toggle={toggleDeleteModal}>
        <ModalHeader toggle={toggleDeleteModal}>Confirm Delete</ModalHeader>
        <ModalBody>
          Are you sure you want to delete the selected items?
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleDelete}>Delete</Button>
          <Button color="secondary" onClick={toggleDeleteModal}>Cancel</Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={editModal} toggle={toggleEditModal}>
      <ModalHeader toggle={toggleEditModal}>Edit Swap Time</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="day">Day</Label>
            <Input type="text" name="day" id="day" value={editFormValues.day} readOnly />
          </FormGroup>
          <FormGroup>
            <Label for="time">Time</Label>
            <Input
              type="text"
              name="time"
              id="time"
              value={editFormValues.time}
              onChange={handleEditChange}
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleEditSubmit}>
          Save
        </Button>
        <Button color="secondary" onClick={toggleEditModal}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>


    </Fragment>
  );
};

const platformOptions = [
  { value: 'MT4', label: 'MT4' },
  { value: 'MT5', label: 'MT5' },
];

export default SwapGroupMapping;
